import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useTranslations } from "@/i18n";
import type { Lang } from "@/types";
import { SearchIcon } from "lucide-react";
import type { FormEventHandler } from "react";

export const ProductsSearchV2 = ({ lang, initialQ }: { lang: Lang; initialQ?: string }) => {
  const t = useTranslations(lang);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const q = formData.get("q") as string;

    const params = new URLSearchParams();
    params.append("q", q);

    window.location.href = `/${lang}?${params.toString()}`;
  };

  return (
    <>
      <form onSubmit={onSubmit} className="flex items-center">
        <Input
          name="q"
          defaultValue={initialQ}
          placeholder={t("common.search")}
          className="rounded-r-none h-9"
        />
        <Button type="submit" className="rounded-l-none" size="sm" color="primary">
          <SearchIcon size={20} />
        </Button>
      </form>
    </>
  );
};
